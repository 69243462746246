<template lang="pug">
div(style="font-size: 16px; height: 100%")
  .full-height.overflow-x-hidden.overflow-y-hidden
    v-card.title-part(color="#363588", tile)
      v-row
        v-col(md="8", sm="6", v-if="$vuetify.breakpoint.smAndUp")
          h5.white--text.font-weight-regular.ml-3(v-if="book.length <= 37") {{ book.title }}
          h5.white--text.font-weight-regular.ml-3(v-else-if="$vuetify.breakpoint.smOnly") {{ book.title.slice(0, 30) }} ...
          h5.white--text.font-weight-regular.ml-3(v-else-if="$vuetify.breakpoint.mdOnly") {{ book.title.slice(0, 65) }} ...
          h5.white--text.font-weight-regular.ml-3(v-else) {{ book.title.slice(0, 90) }} ...
        v-col.mt-n1.text-sm-end.text-center(md="4", sm="6", cols="12")
          addToShelf(:bookId="$route.params.id").mr-0.mr-sm-3
          v-btn.mr-0.mr-sm-3(
            small,
            color="primary",
            :tile="$vuetify.breakpoint.xs"
          )
            router-link(to="/user/home", replace)
              v-icon(color="white") mdi-home
            router-link(to="/results/search", replace)
              v-icon.ml-3(color="white") mdi-magnify
            router-link(to="/user/shelf", replace)
              v-icon.ml-3(color="white") mdi-bookshelf
    v-overlay(opacity=".9" color="#f3f3f3" v-if="book==''").pl-lg-10
      v-progress-circular.ml-lg-15(
        :size="70"
        :width="7"
        color="primary"
        indeterminate)
    pdf-app#pdf-wrapper.mx-auto(
    v-else
    :pdf="'https://ehubstorage.cslib.upd.edu.ph/home/pdf/' + book.pdfUrl",
    :config="config"
  )
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
let getBook = firebase.functions().httpsCallable("getBook");
import PdfApp from "vue-pdf-app";
import addToShelf from "../components/AddToShelf.vue";
import $ from "jquery";
let incrementMonth = firebase.functions().httpsCallable("incrementMonth");
let incrementView = firebase.functions().httpsCallable("incrementView");

export default {
  name: "App",
  components: {
    PdfApp,
    addToShelf,
  },
  data() {
    return {
      book: "",
      userId: "",
      bookId: this.$route.params.id,
      loading: false,
      bookmarks: [],
      config: {
        toolbar: {
          toolbarViewerRight: false,
        },
      },
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
    };
  },
  async created() {
    const id = this.$route.params.id;
    this.book = (await getBook({ id })).data.book;
    $(function() {
      $("#toolbarViewerRight").remove();
      $("#printServiceOverlay").remove();
      $("#secondaryToolbarButtonContainer").remove();
    });
    $(document).bind("keydown", function(e) {
      if (
        ((e.ctrlKey || e.metaKey) &&
          (e.which == 83 || e.which == 80 || e.which == 85)) ||
        e.keyCode == 123
      ) {
        e.preventDefault();
        alert("Saving or Downloading the PDF is forbidden");
        return false;
      }
    });
    $(document).on("contextmenu", function(e) {
      e.preventDefault();
    });
    await incrementView({ id });
    await incrementMonth({
      collection: "views",
      year: this.year,
      month: this.month,
    });
  },

  methods: {},
};
</script>
<style lang="css">
html {
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
}
h3,
.h3 {
  font-size: 1.75rem !important;
}
h4,
.h4 {
  font-size: 1.5rem !important;
}
h5,
.h5 {
  font-size: 1.25rem !important;
}
h6,
.h6 {
  font-size: 1rem !important;
}

#pdf-wrapper {
  width: 100%;
  height: 92%;
}
.title-part {
  height: 7%;
  width: 100%;
}
#thumbnailView {
  width: 100% !important;
}
#outlineView {
  width: 99% !important;
}
#toolbarSidebar {
  background: rgba(53, 51, 143, 0.9) !important;
}
#toolbarViewer {
  height: 32px;
  background: rgba(53, 51, 143, 0.9) !important;
  z-index: 0 !important;
}

#mainContainer {
  background: rgb(141, 156, 184);
}
.page {
  margin-bottom: 10px !important;
}

.full-height {
  height: 100% !important;
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-height: 1025px) {
  #pdf-wrapper {
    width: 100%;
    height: 96%;
  }
  .title-part {
    height: 4%;
    width: 100%;
  }
}

@media (max-height: 900px) {
  #pdf-wrapper {
    width: 100%;
    height: 93%;
  }
  .title-part {
    height: 7%;
    width: 100%;
  }
}
@media (max-height: 800px) {
  #pdf-wrapper {
    width: 100%;
    height: 94%;
  }
  .title-part {
    height: 6%;
    width: 100%;
  }
}
@media (max-height: 435px) {
  #pdf-wrapper {
    width: 100%;
    height: 90%;
  }
  .title-part {
    height: 10%;
    width: 100%;
  }
}
@media (max-height: 320px) {
  #pdf-wrapper {
    width: 100%;
    height: 85%;
  }
  .title-part {
    height: 15%;
    width: 100%;
  }
}
</style>
